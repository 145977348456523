.read-more {
  font-family: "Hind Siliguri", sans-serif;
  color: rgb(153, 237, 252);
}

.zine-selection {
  background-color: rgb(5, 5, 5);
  margin-top: 2%;
  height: 100%;
}

.externals {
  background-color: rgb(5, 5, 5);
  border-bottom: 1px solid rgb(88, 216, 248);
}

.morus-website {
  width: 50%;
  background-color: rgb(5, 5, 5);
  color: rgb(88, 216, 248);
  padding: 10px;
  border: none;
  padding-left: 22%;
  cursor: pointer;
}

.morus-website:hover {
  color: blanchedalmond;
}

.donate {
  width: 50%;
  background-color: rgb(5, 5, 5);
  color: rgb(88, 216, 248);
  padding: 10px;
  border: none;
  cursor: pointer;
  padding-right: 17%;
}

.donate:hover {
  color: blanchedalmond;
}

.zinecat {
  color: blanchedalmond;
  font-family: "Hind Siliguri", sans-serif;
  text-decoration: underline;
  font-weight: lighter;
  letter-spacing: 0.8px;
  cursor: pointer;
}

.high-div {
  background-color: black;
}

.zinecat:hover {
  color: rgb(115, 230, 250);
}

body {
  background-color: rgb(5, 5, 5);
}

.intro-btn {
  margin-left: 24%;
  margin-right: 24%;
  cursor: pointer;
}

.nav-tag {
  padding: 1.2% 0 1.2% 19.3%;
  border-bottom: 2px solid rgb(88, 216, 248);
  border-top: 2px solid blanchedalmond;
}

@media screen and (min-width: 500px) {
  .wrapper {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
  }

  .enter {
    color: rgb(76, 205, 245);
  }

  .enter:hover {
    color: red;
    cursor: pointer;
  }

  .wrapper video {
    width: 900px;
    height: auto;
    object-fit: cover;
    position: absolute;
    top: 0;
    bottom: 0;
  }
  .wrapper h1 {
    background-color: #fff;
    height: 48vh;
    color: black;
    mix-blend-mode: screen;
    font-family: "Allerta Stencil", sans-serif;
    font-size: 110pt;
    text-transform: uppercase;
    text-align: center;
    font-weight: 1000;
    line-height: 95px;
    padding-top: 30px;
    margin-top: 0%;
  }

  .zine-library {
    color: black;
    font-size: 94pt;
    letter-spacing: 2px;
  }
  .opening-top-section {
    background-color: black;
  }
  .opening {
    font-size: 10.5pt;
    padding-top: 20px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 10px;
    font-family: "Hind Siliguri", sans-serif;
    color: blanchedalmond;
    text-indent: 40px;
    border: none;
    text-align: justify;
  }

  .opening-p2 {
    font-size: 11pt;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 30px;
    font-family: "Hind Siliguri", sans-serif;
    text-align: justify;
    color: blanchedalmond;
    text-indent: 40px;
    border: none;
  }
  .admin {
    color: black;
  }

  .button-54 {
    font-family: "Open Sans", sans-serif;
    font-size: 6pt;
    letter-spacing: 2px;
    text-decoration: none;
    text-transform: uppercase;
    color: rgb(50, 50, 51);
    cursor: pointer;
    border: 3px solid;
    padding: 0.75em 15em;
    box-shadow: 1px 1px 0px 0px, 2px 2px 0px 0px, 3px 3px 0px 0px,
      4px 4px 0px 0px, 5px 5px 0px 0px;
    position: relative;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    margin-bottom: 15px;
    margin-top: 10px;
  }

  .button-54:active {
    box-shadow: 0px 0px 0px 0px;
    top: 5px;
    left: 5px;
  }

  @media (min-width: 400px) {
    .button-54 {
      padding: 0.25em 0.75em;
    }
  }
}

@media screen and (min-width: 600px) {
  .wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    top: 0;
    bottom: 0;
  }

  .enter {
    color: rgb(76, 205, 245);
  }

  .enter:hover {
    color: red;
    cursor: pointer;
  }

  .wrapper video {
    width: 900px;
    height: auto;
    object-fit: cover;
    position: absolute;
    top: 0;
    bottom: 0;
  }

  .library {
    color: black;
    font-size: 55pt;
  }
  .wrapper h1 {
    background-color: rgb(5, 5, 5);
    color: rgb(88, 216, 248);
    opacity: 1;
    height: 46vh;
    mix-blend-mode: screen;
    font-family: "Allerta Stencil", sans-serif;
    font-size: 125pt;
    text-transform: uppercase;
    text-align: center;
    font-weight: 1000;
    padding-top: 30px;
    margin-top: 0%;
    line-height: 100pt;
  }

  .zine-library {
    color: rgb(88, 216, 248);
    font-size: 105pt;
    letter-spacing: 2px;
  }

  .opening-top-section {
    background-color: black;
    border-top: 4px solid rgb(88, 216, 248);
    border-bottom: 2px solid rgb(88, 216, 248);
  }
  .opening {
    font-size: 11pt;
    padding-top: 30px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 10px;
    font-family: "Hind Siliguri", sans-serif;
    color: blanchedalmond;
    text-indent: 40px;
    border: none;
    text-align: justify;
  }

  .opening-p2 {
    font-size: 11pt;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 30px;
    font-family: "Hind Siliguri", sans-serif;
    text-align: justify;
    color: blanchedalmond;
    text-indent: 40px;
    border: none;
  }

  .admin {
    color: black;
  }

  .intro {
    margin-left: 17%;
    margin-right: 17%;
  }

  .nav-tag {
    padding: 1.4% 0 1.4% 17.6%;
    border-bottom: 2px solid rgb(88, 216, 248);
    border-top: 2px solid blanchedalmond;
  }

  .intro-btn {
    margin-left: 21%;
    margin-right: 21%;
  }

  .nav-tag {
    padding: 1.2% 0 1.2% 9%;
    border-bottom: 2px solid rgb(88, 216, 248);
    border-top: 2px solid blanchedalmond;
  }

  .button-54 {
    font-family: "Open Sans", sans-serif;
    font-size: 7pt;
    letter-spacing: 2px;
    text-decoration: none;
    text-transform: uppercase;
    color: rgb(50, 50, 51);
    cursor: pointer;
    border: 3px solid;
    padding: 0.75em 15em;
    box-shadow: 1px 1px 0px 0px, 2px 2px 0px 0px, 3px 3px 0px 0px,
      4px 4px 0px 0px, 5px 5px 0px 0px;
    position: relative;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    margin-bottom: 15px;
    margin-top: 10px;
  }

  .button-54:active {
    box-shadow: 0px 0px 0px 0px;
    top: 5px;
    left: 5px;
  }

  @media (min-width: 508px) {
    .button-54 {
      padding: 0.25em 0.75em;
    }
  }
}

@media screen and (min-width: 1000px) {
  .wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    top: 0;
    bottom: 0;
  }

  .enter:hover {
    color: red;
    cursor: pointer;
  }

  .wrapper video {
    width: 1150px;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    bottom: 0;
  }
  .wrapper h1 {
    background-color: rgb(5, 5, 5);
    color: rgb(88, 216, 248);
    height: 59vh;
    mix-blend-mode: screen;
    font-family: "Allerta Stencil", sans-serif;
    font-size: 194pt;
    line-height: 92pt;
    text-transform: uppercase;
    text-align: center;
    font-weight: 1000;
    padding-top: 6%;
  }

  .zine-library {
    color: rgb(88, 216, 248);
    font-size: 105pt;
    letter-spacing: 2px;
  }

  .opening-top-section {
    background-color: black;
    border-top: 4px solid rgb(88, 216, 248);
    border-bottom: 2px solid rgb(88, 216, 248);
  }
  .opening {
    font-size: 12.5pt;
    padding-top: 20px;
    padding-left: 45px;
    padding-right: 45px;
    padding-bottom: 10px;
    line-height: 21pt;
    font-family: "Hind Siliguri", sans-serif;
    color: blanchedalmond;
    text-indent: 40px;
    border: none;
    text-align: justify;
  }

  .opening-p2 {
    font-size: 12.5pt;
    padding-left: 45px;
    padding-right: 45px;
    padding-bottom: 25px;
    font-family: "Hind Siliguri", sans-serif;
    text-align: justify;
    color: blanchedalmond;
    text-indent: 40px;
    line-height: 21pt;
    border: none;
  }
  .admin {
    color: black;
  }

  .intro {
    margin-left: 24%;
    margin-right: 24%;
  }

  .nav-tag {
    padding: 1.4% 0 1.4% 9.8%;
    border-bottom: 2px solid rgb(88, 216, 248);
    border-top: 2px solid blanchedalmond;
  }

  .button-54 {
    font-family: "Open Sans", sans-serif;
    font-size: 10pt;
    letter-spacing: 2px;
    background-color: whitesmoke;
    color: rgb(50, 50, 51);
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;
    border: 3px solid;
    padding: 0.75em 15em;
    box-shadow: 1px 1px 0px 0px, 2px 2px 0px 0px, 3px 3px 0px 0px,
      4px 4px 0px 0px, 5px 5px 0px 0px;
    position: relative;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    margin-bottom: 13px;
  }

  .button-54:active {
    box-shadow: 0px 0px 0px 0px;
    top: 5px;
    left: 5px;
  }

  @media (min-width: 508px) {
    .button-54 {
      padding: 0.25em 0.75em;
    }
  }
}

@media screen and (min-width: 1000px) and (min-height: 1000px) {
  .wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    top: 0;
    bottom: 0;
  }

  .enter {
    color: rgb(76, 205, 245);
  }

  .enter:hover {
    color: red;
    cursor: pointer;
  }

  .wrapper video {
    width: 1400px;
    height: auto;
    object-fit: cover;
    position: absolute;
    top: 0;
    bottom: 0;
  }

  .wrapper h1 {
    background-color: rgb(5, 5, 5);
    color: rgb(88, 216, 248);
    height: 50vh;
    mix-blend-mode: screen;
    font-family: "Allerta Stencil", sans-serif;
    font-size: 200pt;
    text-transform: uppercase;
    text-align: center;
    font-weight: 1000;
    padding-top: 60px;
    margin-top: 0%;
    line-height: 147pt;
  }

  .zine-library {
    color: rgb(88, 216, 248);
    font-size: 170pt;
    letter-spacing: 2px;
  }

  .opening-top-section {
    background-color: black;
    border-top: 4px solid rgb(88, 216, 248);
    border-bottom: 2px solid rgb(88, 216, 248);
  }
  .opening {
    font-size: 14pt;
    padding-top: 30px;
    padding-left: 36px;
    padding-right: 36px;
    padding-bottom: 20px;
    line-height: 21pt;
    font-family: "Hind Siliguri", sans-serif;
    color: blanchedalmond;
    text-indent: 40px;
    border: none;
    text-align: justify;
  }

  .opening-p2 {
    font-size: 14pt;
    padding-left: 36px;
    padding-right: 36px;
    padding-bottom: 35px;
    font-family: "Hind Siliguri", sans-serif;
    text-align: justify;
    color: blanchedalmond;
    text-indent: 40px;
    line-height: 21pt;
    border: none;
  }

  .admin {
    color: black;
  }
}

@media screen and (min-width: 1200px) {
  .wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    top: 0;
    bottom: 0;
  }

  .enter:hover {
    color: red;
    cursor: pointer;
  }

  .wrapper video {
    width: 1350px;
    height: auto;
    object-fit: cover;
    position: absolute;
    top: 0;
    bottom: 0;
  }

  .wrapper h1 {
    background-color: rgb(5, 5, 5);
    color: rgb(88, 216, 248);
    height: 61vh;
    mix-blend-mode: screen;
    font-family: "Allerta Stencil", sans-serif;
    font-size: 211pt;
    line-height: 119pt;
    text-transform: uppercase;
    text-align: center;
    font-weight: 1000;
    padding-top: 5%;
  }

  .zine-library {
    color: rgb(88, 216, 248);
    font-size: 137pt;
    letter-spacing: 0px;
  }

  .opening-top-section {
    background-color: black;
    border-top: 4px solid rgb(88, 216, 248);
    border-bottom: 2px solid rgb(88, 216, 248);
  }
  .opening {
    font-size: 13pt;
    padding-top: 25px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 10px;
    line-height: 23pt;
    font-family: "Hind Siliguri", sans-serif;
    color: blanchedalmond;
    text-indent: 40px;
    border: none;
    text-align: justify;
  }

  .opening-p2 {
    font-size: 14pt;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 30px;
    line-height: 23pt;
    font-family: "Hind Siliguri", sans-serif;
    text-align: justify;
    color: blanchedalmond;
    text-indent: 40px;
    border: none;
  }
  .admin {
    color: black;
  }

  .nav-tag {
    padding: 1.3% 0 1.3% 14.75%;
    border-bottom: 2px solid rgb(88, 216, 248);
    border-top: 2px solid blanchedalmond;
  }

  .button-54 {
    font-family: "Open Sans", sans-serif;
    font-size: 13.5px;
    letter-spacing: 2px;
    text-decoration: none;
    text-transform: uppercase;
    color: rgb(50, 50, 51);
    background-color: whitesmoke;
    cursor: pointer;
    border: 3px solid;
    padding: 0.75em 15em;
    box-shadow: 1px 1px 0px 0px, 2px 2px 0px 0px, 3px 3px 0px 0px,
      4px 4px 0px 0px, 5px 5px 0px 0px;
    position: relative;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    margin-bottom: 15px;
    margin-top: 10px;
  }

  .button-54:active {
    box-shadow: 0px 0px 0px 0px;
    top: 5px;
    left: 5px;
  }

  @media (min-width: 508px) {
    .button-54 {
      padding: 0.25em 0.75em;
    }
  }
}

@media screen and (min-width: 1300px) {
  .wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    top: 0;
    bottom: 0;
  }

  .enter {
    color: rgb(76, 205, 245);
  }

  .enter:hover {
    color: red;
    cursor: pointer;
  }

  .wrapper video {
    width: 1700px;
    height: 100vh;
    object-fit: cover;
    position: absolute;
    top: 0;
    bottom: 0;
  }

  .wrapper h1 {
    background-color: rgb(5, 5, 5);
    color: rgb(88, 216, 248);
    height: 61vh;
    mix-blend-mode: screen;
    font-family: "Allerta Stencil", sans-serif;
    font-size: 218pt;
    text-transform: uppercase;
    text-align: center;
    font-weight: 1000;
    padding-top: 6%;
    line-height: 123pt;
  }

  .zine-library {
    color: rgb(88, 216, 248);
    font-size: 142pt;
    letter-spacing: 1px;
  }

  .opening-top-section {
    background-color: black;
    border-top: 4px solid rgb(88, 216, 248);
    border-bottom: 2px solid rgb(88, 216, 248);
  }
  .opening {
    font-size: 14pt;
    padding-top: 25px;
    padding-left: 60px;
    padding-right: 60px;
    padding-bottom: 10px;
    font-family: "Hind Siliguri", sans-serif;
    color: blanchedalmond;
    text-indent: 25px;
    border: none;
    text-align: justify;
    line-height: 25pt;
  }

  .opening-p2 {
    font-size: 14pt;
    padding-left: 60px;
    padding-right: 60px;
    padding-bottom: 35px;
    font-family: "Hind Siliguri", sans-serif;
    text-align: justify;
    color: blanchedalmond;
    text-indent: 25px;
    line-height: 25pt;
  }

  .admin {
    color: black;
  }

  .intro {
    margin-left: 24%;
    margin-right: 24%;
  }

  .nav-tag {
    padding: 1.3% 0 1.3% 14.3%;
    border-bottom: 2px solid rgb(88, 216, 248);
    border-top: 2px solid blanchedalmond;
  }
}

@media screen and (min-width: 1300px) and (min-height: 1000px) {
  .wrapper h1 {
    background-color: rgb(5, 5, 5);
    color: rgb(88, 216, 248);
    height: 43vh;
    mix-blend-mode: screen;
    font-family: "Allerta Stencil", sans-serif;
    font-size: 215pt;
    text-transform: uppercase;
    text-align: center;
    font-weight: 1000;
    padding-top: 6%;
    line-height: 115pt;
  }
}

@media screen and (min-width: 1400px) {
  .wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    top: 0;
    bottom: 0;
  }

  .enter:hover {
    color: red;
    cursor: pointer;
  }

  .wrapper video {
    width: 1600px;
    height: 90vh;
    object-fit: cover;
    position: absolute;
    top: 0;
    bottom: 0;
  }

  .wrapper h1 {
    background-color: rgb(5, 5, 5);
    color: rgb(88, 216, 248);
    height: 59vh;
    mix-blend-mode: screen;
    font-family: "Allerta Stencil", sans-serif;
    font-size: 255pt;
    text-transform: uppercase;
    text-align: center;
    font-weight: 1000;
    padding: 7% 0% 0% 0%;
    line-height: 178px;
    letter-spacing: 5px;
  }

  .zine-library {
    color: rgb(88, 216, 248);
    font-size: 150pt;
    letter-spacing: 0px;
  }

  .opening-top-section {
    background-color: black;
    border-top: 4px solid rgb(88, 216, 248);
    border-bottom: 2px solid rgb(88, 216, 248);
  }
  .opening {
    font-size: 14pt;
    padding-top: 30px;
    padding-left: 55px;
    padding-right: 65px;
    padding-bottom: 20px;
    font-family: "Hind Siliguri", sans-serif;
    color: blanchedalmond;
    text-indent: 30px;
    border: none;
    text-align: justify;
    line-height: 28pt;
  }

  .opening-p2 {
    font-size: 14pt;
    padding-left: 55px;
    padding-right: 65px;
    padding-bottom: 40px;
    font-family: "Hind Siliguri", sans-serif;
    text-align: justify;
    color: blanchedalmond;
    text-indent: 30px;
    line-height: 28pt;
  }

  .admin {
    color: black;
  }

  .intro-btn {
    margin-left: 24%;
    margin-right: 24%;
    cursor: pointer;
  }

  .nav-tag {
    padding: 1.2% 0 1.2% 15.3%;
    border-bottom: 2px solid rgb(88, 216, 248);
    border-top: 2px solid blanchedalmond;
  }
}

@media screen and (min-width: 1500px) {
  .nav-tag {
    padding: 1.3% 0 1.3% 19.3%;
    border-bottom: 2px solid rgb(88, 216, 248);
    border-top: 2px solid blanchedalmond;
  }
}

@media screen and (min-width: 1600px) {
  .wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    top: 0;
    bottom: 0;
  }

  .library {
    color: rgb(7, 7, 7);
    font-size: 90px;
  }

  .enter:hover {
    color: red;
    cursor: pointer;
  }

  .wrapper video {
    width: 1700px;
    height: 100vh;
    object-fit: cover;
    position: absolute;
    top: 0;
    bottom: 0;
  }
  .wrapper h1 {
    background-color: rgb(5, 5, 5);
    color: rgb(88, 216, 248);
    height: 60vh;
    mix-blend-mode: screen;
    font-family: "Allerta Stencil", sans-serif;
    font-size: 280pt;
    line-height: 216px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 1200;
    padding: 5% 0 1% 0;
    letter-spacing: 18px;
  }

  .zine-library {
    color: rgb(88, 216, 248);
    font-size: 175pt;
    letter-spacing: 0px;
  }

  .opening-top-section {
    background-color: black;
    border-top: 4px solid rgb(88, 216, 248);
    border-bottom: 1px solid rgb(88, 216, 248);
  }
  .opening {
    font-size: 16pt;
    padding-top: 30px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 10px;
    font-family: "Hind Siliguri", sans-serif;
    color: blanchedalmond;
    text-indent: 30px;
    border: none;
    text-align: justify;
    line-height: 32pt;
  }

  .opening-p2 {
    font-size: 16.5pt;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 40px;
    font-family: "Hind Siliguri", sans-serif;
    text-align: justify;
    color: blanchedalmond;
    text-indent: 30px;
    line-height: 32pt;
  }

  .admin {
    color: black;
  }

  .intro-btn {
    margin-left: 24%;
    margin-right: 24%;
  }

  .nav-tag {
    padding: 1.3% 0 1.3% 15.7%;
    border-bottom: 2px solid rgb(88, 216, 248);
    border-top: 2px solid blanchedalmond;
  }

  .morus-website {
    font-size: 11pt;
    padding-left: 18.3%;
  }

  .donate {
    font-size: 11pt;
  }
}

@media all and (min-width: 1900px) {
  .wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    top: 0;
    bottom: 0;
  }

  .library {
    color: rgb(7, 7, 7);
    font-size: 100px;
  }

  .wrapper h1 {
    background-color: rgb(5, 5, 5);
    color: rgb(88, 216, 248);
    height: 58vh;
    mix-blend-mode: screen;
    font-family: "Allerta Stencil", sans-serif;
    font-size: 320pt;
    line-height: 242px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 1000;
    padding: 7% 0 0% 0;
    letter-spacing: 1px;
  }

  .zine-library {
    color: rgb(88, 216, 248);
    font-size: 202pt;
    letter-spacing: 2px;
  }

  .enter:hover {
    color: red;
    cursor: pointer;
  }

  .wrapper video {
    width: 1990px;
    height: 90vh;
    object-fit: cover;
    position: absolute;
    top: 0;
    bottom: 0;
  }

  .opening-top-section {
    background-color: black;
  }
  .opening {
    font-size: 18pt;
    padding-top: 40px;
    padding-left: 75px;
    padding-right: 90px;
    padding-bottom: 20px;
    line-height: 35pt;
    font-family: "Hind Siliguri", sans-serif;
    color: blanchedalmond;
    text-indent: 35px;
    border: none;
    text-align: justify;
  }

  .opening-p2 {
    font-size: 18pt;
    padding-left: 80px;
    padding-right: 80px;
    padding-bottom: 50px;
    line-height: 35pt;
    font-family: "Hind Siliguri", sans-serif;
    text-align: justify;
    color: blanchedalmond;
    text-indent: 35px;
    border: none;
  }

  .admin {
    color: black;
  }
  .intro {
    margin-left: 26%;
    margin-right: 26%;
  }

  .nav-tag {
    padding: 1.4% 0 1.4% 16.3%;
    border-bottom: 2px solid rgb(88, 216, 248);
    border-top: 2px solid blanchedalmond;
  }

  .morus-website {
    font-size: 12pt;
  }

  .donate {
    font-size: 12pt;
  }
}

@media screen and (min-width: 2500px){
  .wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    top: 0;
    bottom: 0;
  }

  .library {
    color: rgb(7, 7, 7);
    font-size: 100px;
  }

  .wrapper h1 {
    background-color: rgb(5, 5, 5);
    color: rgb(88, 216, 248);
    height: 58vh;
    mix-blend-mode: screen;
    font-family: "Allerta Stencil", sans-serif;
    font-size: 420pt;
    line-height: 292px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 1000;
    padding: 7% 0 0% 0;
    letter-spacing: 1px;
  }

  .zine-library {
    color: rgb(88, 216, 248);
    font-size: 272pt;
    letter-spacing: 2px;
  }

  .enter:hover {
    color: red;
    cursor: pointer;
  }

  .wrapper video {
    width: 2600px;
    height: 90vh;
    object-fit: cover;
    position: absolute;
    top: 0;
    bottom: 0;
  }

  .opening-top-section {
    background-color: black;
  }
  .opening {
    font-size: 26pt;
    padding-top: 40px;
    padding-left: 75px;
    padding-right: 90px;
    padding-bottom: 20px;
    line-height: 35pt;
    font-family: "Hind Siliguri", sans-serif;
    color: blanchedalmond;
    text-indent: 35px;
    border: none;
    text-align: justify;
  }

  .opening-p2 {
    font-size: 26pt;
    padding-left: 80px;
    padding-right: 80px;
    padding-bottom: 50px;
    line-height: 35pt;
    font-family: "Hind Siliguri", sans-serif;
    text-align: justify;
    color: blanchedalmond;
    text-indent: 35px;
    border: none;
  }

  .admin {
    color: black;
  }
  .intro {
    margin-left: 26%;
    margin-right: 26%;
  }

  .nav-tag {
    padding: 1.4% 0 1.4% 16.3%;
    border-bottom: 2px solid rgb(88, 216, 248);
    border-top: 2px solid blanchedalmond;
  }

  .morus-website {
    font-size: 20pt;
  }

  .donate {
    font-size: 20pt;
  }
}
