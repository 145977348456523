.top-grid-div {
  width: 110%;
  background-color: rgb(3, 3, 3);
}

.filter {
  height: 23pt;
  width: 310px;
  margin-top: 40px;
  margin-left: 38px;
  margin-bottom: 40px;
  border-radius: 30px;
  background-color: rgb(59, 59, 56);
  font-size: 16px;
  padding-left: 25px;
  color: rgb(224, 224, 224);
  border: 1px inset rgb(97, 95, 95);
  box-shadow: none;
  letter-spacing: 1.1px;
  font-family: "Hind Siliguri", sans-serif;
  cursor: pointer;
}

.home {
  cursor: pointer;
}

.filter:hover {
  background-color: rgb(59, 59, 56);
}

.grid-div {
  display: grid;
  grid-template-columns: 0.5fr 0.5fr 0.5fr;
  grid-gap: 5px;
  padding: 50px 0px 35px 35px;
}
.img-div {
  width: 85%;
}

.grid-img {
  max-height: 300px;
  box-shadow: 1px 18px 15px 2px rgb(7, 7, 7);
  height: auto;
  width: auto;
  margin-bottom: 20px;
  border: 2px solid black;
}

.border-grid {
  border-bottom: 1px solid white;
  width: 227px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.grid-img:hover {
  box-shadow: 1px 1px 1px 2px rgb(240, 247, 205);
}

.hide-btn {
  background-color: rgb(87, 15, 15);
  color: rgb(247, 247, 242);
  height: 24px;
  width: 27px;
  border-radius: 10px;
  margin-bottom: 10px;
  margin-left: 3px;
  cursor: pointer;
}

.hide-btn:hover {
  background-color: rgb(122, 1, 1);
}

.grid-btn {
  background-color: rgb(247, 245, 223);
  color: rgb(8, 8, 8);
  width: 80px;
  font-size: 7.5pt;
  height: 30px;
  margin-left: 3px;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 22px;
  cursor: pointer;
}

.grid-btn:hover {
  background-color: rgb(247, 251, 252);
}

.grid-data {
  font-size: 9.5pt;
  text-align: left;
  margin-top: 3px;
  margin-left: 2px;
  color: white;
}

.grid-span {
  font-size: 10.2pt;
  color: rgb(142, 232, 250);
}

.metadata-div {
  width: 230px;
}

.back-to-top {
  cursor: pointer;
  margin-top: 3%;
}

@media screen and (min-width: 300px) {
  body {
    background-color: rgb(43, 44, 44);
  }

  .top-grid-div {
    width: 100%;
    background-color: rgb(43, 44, 44);
  }

  .home {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    margin-left: 14%;
    font-size: 6pt;
    letter-spacing: 1.1px;
    font-family: "Hind Siliguri", sans-serif;
    color: rgb(128, 230, 250);
    background-color: rgb(5, 5, 5);
    border: none;
  }

  .home:hover {
    background-color: rgb(5, 5, 5);
    box-shadow: 2px 1px 13px rgb(240, 247, 205);
  }

  strong {
    letter-spacing: 1.1px;
    font-family: "Hind Siliguri", sans-serif;
    font-size: 8pt;
    color: rgb(207, 207, 207);
    margin-left: 35px;
  }

  .filter {
    height: 18pt;
    width: 200px;
    margin-top: 40px;
    margin-left: 20px;
    margin-bottom: 5px;
    border-radius: 30px;
    background-color: rgb(59, 59, 56);
    font-size: 9pt;
    padding-left: 25px;
    color: rgb(139, 139, 138);
    border: 1px inset rgb(97, 95, 95);
    box-shadow: none;
    letter-spacing: 1.1px;
    font-family: "Hind Siliguri", sans-serif;
  }

  .filter:hover {
    background-color: rgb(59, 59, 56);
  }

  .grid-div {
    display: grid;
    grid-template-columns: 0.5fr 0.5fr;
    grid-gap: 1px;
    padding: 50px 0px 35px 40px;
  }
  .img-div {
    width: 85%;
  }

  .grid-img {
    max-height: 240px;
    box-shadow: 1px 18px 15px 2px rgb(7, 7, 7);
    height: auto;
    width: auto;
    margin-bottom: 2px;
  }

  .border-grid {
    border-bottom: 1px solid white;
    width: 195px;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .grid-img:hover {
    box-shadow: 1px 1px 1px 2px rgb(240, 247, 205);
  }

  .hide-btn {
    background-color: rgb(87, 15, 15);
    color: rgb(247, 247, 242);
    height: 24px;
    width: 27px;
    border-radius: 10px;
    margin-bottom: 10px;
    margin-left: 3px;
  }

  .hide-btn:hover {
    background-color: rgb(122, 1, 1);
  }

  .grid-btn {
    color: rgb(128, 230, 250);
    background-color: rgb(5, 5, 5);
    width: 82px;
    height: 34px;
    margin-left: 3px;
    border-radius: 10px;
    margin-bottom: 30px;
    font-size: 7.5pt;
    letter-spacing: 0.8px;
    font-family: "Hind Siliguri", sans-serif;
  }

  .grid-btn:hover {
    background-color: rgb(5, 5, 5);
    box-shadow: 0px 2px 2px rgb(240, 247, 205);
  }

  .back-to-top {
    width: 52px;
    height: 52px;
    border-radius: 50%;
    margin-left: 86%;
    margin-bottom: 25px;
    font-size: 7pt;
    letter-spacing: 1.1px;
    font-family: "Hind Siliguri", sans-serif;
    color: rgb(128, 230, 250);
    background-color: rgb(5, 5, 5);
    border: none;
  }

  .back-to-top:hover {
    background-color: rgb(5, 5, 5);
    box-shadow: 2px 1px 13px rgb(240, 247, 205);
  }

  .grid-data {
    font-size: 8pt;
    text-align: left;
    margin-top: 3px;
    margin-left: 2px;
    color: white;
  }

  .grid-span {
    font-size: 9pt;
    color: rgb(142, 232, 250);
  }

  .metadata-div {
    width: 190px;
  }
}

@media screen and (min-width: 700px) {
  body {
    background-color: rgb(43, 44, 44);
  }

  .top-grid-div {
    width: 100%;
    background-color: rgb(43, 44, 44);
  }

  .home {
    width: 52px;
    height: 52px;
    border-radius: 50%;
    margin-left: 24%;
    font-size: 6.5pt;
    letter-spacing: 1.1px;
    font-family: "Hind Siliguri", sans-serif;
    color: rgb(128, 230, 250);
    background-color: rgb(5, 5, 5);
    border: none;
  }

  .home:hover {
    background-color: rgb(5, 5, 5);
    box-shadow: 2px 1px 13px rgb(240, 247, 205);
  }

  strong {
    letter-spacing: 1.1px;
    font-family: "Hind Siliguri", sans-serif;
    font-size: 10.5pt;
    color: rgb(207, 207, 207);
    margin-left: 43px;
  }

  .filter {
    height: 22pt;
    width: 230px;
    margin-top: 40px;
    margin-left: 22px;
    margin-bottom: 40px;
    border-radius: 30px;
    background-color: rgb(59, 59, 56);
    font-size: 9pt;
    padding-left: 25px;
    color: rgb(139, 139, 138);
    border: 1px inset rgb(97, 95, 95);
    box-shadow: none;
    letter-spacing: 1.1px;
    font-family: "Hind Siliguri", sans-serif;
  }

  .filter:hover {
    background-color: rgb(59, 59, 56);
  }

  .grid-div {
    display: grid;
    grid-template-columns: 0.5fr 0.5fr 0.5fr;
    grid-gap: 20px;
    padding: 50px 0px 5px 45px;
  }
  .img-div {
    width: 85%;
  }

  .grid-img {
    max-height: 250px;
    box-shadow: 1px 18px 15px 2px rgb(7, 7, 7);
    height: auto;
    width: auto;
    margin-bottom: 7px;
  }

  .border-grid {
    border-bottom: 1px solid white;
    width: 200px;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .grid-img:hover {
    box-shadow: 1px 1px 1px 2px rgb(240, 247, 205);
  }

  .hide-btn {
    background-color: rgb(87, 15, 15);
    color: rgb(247, 247, 242);
    height: 24px;
    width: 27px;
    border-radius: 10px;
    margin-bottom: 10px;
    margin-left: 3px;
  }

  .hide-btn:hover {
    background-color: rgb(122, 1, 1);
  }
  .grid-btn {
    color: rgb(128, 230, 250);
    background-color: rgb(5, 5, 5);
    width: 82px;
    height: 34px;
    margin-left: 3px;
    border-radius: 10px;
    margin-bottom: 30px;
    font-size: 7.5pt;
    letter-spacing: 0.8px;
    font-family: "Hind Siliguri", sans-serif;
  }

  .grid-btn:hover {
    background-color: rgb(5, 5, 5);
    box-shadow: 0px 2px 2px rgb(240, 247, 205);
  }

  .grid-data {
    font-size: 7pt;
    text-align: left;
    margin-top: 3px;
    margin-left: 2px;
    color: white;
  }

  .grid-span {
    font-size: 8pt;
    color: rgb(153, 237, 252);
  }

  .metadata-div {
    width: 200px;
  }
  .back-to-top {
    width: 52px;
    height: 52px;
    border-radius: 50%;
    margin-left: 86%;
    margin-bottom: 25px;
    font-size: 7pt;
    letter-spacing: 1.1px;
    font-family: "Hind Siliguri", sans-serif;
    color: rgb(128, 230, 250);
    background-color: rgb(5, 5, 5);
    border: none;
  }

  .back-to-top:hover {
    background-color: rgb(5, 5, 5);
    box-shadow: 2px 1px 13px rgb(240, 247, 205);
  }
}

@media screen and (min-width: 900px) {
  body {
    background-color: rgb(43, 44, 44);
  }

  .top-grid-div {
    width: 100%;
    background-color: rgb(43, 44, 44);
  }

  .home {
    width: 52px;
    height: 52px;
    border-radius: 50%;
    margin-left: 43%;
    font-size: 6.5pt;
    letter-spacing: 1.1px;
    font-family: "Hind Siliguri", sans-serif;
    color: rgb(128, 230, 250);
    background-color: rgb(5, 5, 5);
    border: none;
  }

  .home:hover {
    background-color: rgb(5, 5, 5);
    box-shadow: 2px 1px 13px rgb(240, 247, 205);
  }

  strong {
    letter-spacing: 1.1px;
    font-family: "Hind Siliguri", sans-serif;
    font-size: 10.5pt;
    color: rgb(207, 207, 207);
    margin-left: 90px;
  }

  .filter {
    height: 22pt;
    width: 230px;
    margin-top: 40px;
    margin-left: 22px;
    margin-bottom: 50px;
    border-radius: 30px;
    background-color: rgb(59, 59, 56);
    font-size: 11pt;
    padding-left: 25px;
    color: rgb(139, 139, 138);
    border: 1px inset rgb(97, 95, 95);
    box-shadow: none;
    letter-spacing: 1.1px;
    font-family: "Hind Siliguri", sans-serif;
  }

  .filter:hover {
    background-color: rgb(59, 59, 56);
  }

  .grid-div {
    display: grid;
    grid-template-columns: 0.8fr 0.8fr 0.8fr;
    grid-gap: 5px;
    padding: 50px 0px 5px 85px;
  }
  .img-div {
    width: 85%;
  }

  .grid-img {
    max-height: 310px;
    box-shadow: 1px 18px 15px 2px rgb(7, 7, 7);
    height: auto;
    width: auto;
    margin-bottom: 2px;
  }

  .border-grid {
    border-bottom: 1px solid white;
    width: 233px;
    margin-bottom: 10px;
    margin-top: 20px;
  }

  .grid-img:hover {
    box-shadow: 1px 1px 1px 2px rgb(240, 247, 205);
  }

  .hide-btn {
    background-color: rgb(87, 15, 15);
    color: rgb(247, 247, 242);
    height: 19px;
    width: 22px;
    border-radius: 10px;
    margin-bottom: 1px;
    margin-top: 15px;
    margin-left: 3px;
  }

  .hide-btn:hover {
    background-color: rgb(122, 1, 1);
  }

  .grid-btn {
    color: rgb(128, 230, 250);
    background-color: rgb(5, 5, 5);
    width: 82px;
    height: 34px;
    margin-left: 3px;
    border-radius: 10px;
    margin-bottom: 30px;
    font-size: 7.5pt;
    letter-spacing: 0.8px;
    font-family: "Hind Siliguri", sans-serif;
  }

  .grid-btn:hover {
    background-color: rgb(5, 5, 5);
    box-shadow: 0px 2px 2px rgb(240, 247, 205);
  }

  .grid-data {
    font-size: 7.9pt;
    text-align: left;
    margin-top: 3px;
    margin-left: 2px;
    color: white;
    line-height: 10.75pt;
  }

  .grid-span {
    font-size: 8.5pt;
    color: rgb(153, 237, 252);
  }

  .metadata-div {
    width: 230px;
  }

  .back-to-top {
    width: 52px;
    height: 52px;
    border-radius: 50%;
    margin-left: 86%;
    margin-bottom: 25px;
    font-size: 7pt;
    letter-spacing: 1.1px;
    font-family: "Hind Siliguri", sans-serif;
    color: rgb(128, 230, 250);
    background-color: rgb(5, 5, 5);
    border: none;
  }

  .back-to-top:hover {
    background-color: rgb(5, 5, 5);
    box-shadow: 2px 1px 13px rgb(240, 247, 205);
  }
}

@media screen and (min-width: 1100px) {
  body {
    background-color: rgb(43, 44, 44);
  }

  strong {
    letter-spacing: 1.1px;
    font-family: "Hind Siliguri", sans-serif;
    font-size: 10.5pt;
    color: rgb(207, 207, 207);
    margin-left: 70px;
  }

  .top-grid-div {
    width: 100%;
    background-color: rgb(43, 44, 44);
  }

  .filter {
    height: 23pt;
    width: 310px;
    margin-top: 60px;
    margin-left: 28px;
    margin-bottom: 70px;
    border-radius: 30px;
    background-color: rgb(59, 59, 56);
    font-size: 11pt;
    padding-left: 25px;
    color: rgb(139, 139, 138);
    border: 1px inset rgb(97, 95, 95);
    box-shadow: none;
    letter-spacing: 1.1px;
    font-family: "Hind Siliguri", sans-serif;
  }

  .filter:hover {
    background-color: rgb(59, 59, 56);
  }

  .home {
    width: 52px;
    height: 52px;
    border-radius: 50%;
    margin-left: 40%;
    font-size: 6.5pt;
    letter-spacing: 1.1px;
    font-family: "Hind Siliguri", sans-serif;
    color: rgb(128, 230, 250);
    background-color: rgb(5, 5, 5);
    border: none;
  }

  .home:hover {
    background-color: rgb(5, 5, 5);
    box-shadow: 2px 1px 13px rgb(240, 247, 205);
  }

  .grid-div {
    display: grid;
    grid-template-columns: 0.8fr 0.8fr 0.8fr 0.8fr;
    grid-gap: 20px;
    padding: 50px 0px 0px 65px;
  }

  .grid-img {
    max-height: 280px;
    box-shadow: 1px 18px 15px 2px rgb(7, 7, 7);
    height: auto;
    width: auto;
    margin-bottom: 5px;
  }

  .border-grid {
    border-bottom: 1px solid white;
    width: 233px;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .grid-img:hover {
    box-shadow: 1px 1px 1px 2px rgb(240, 247, 205);
  }

  .hide-btn {
    background-color: rgb(87, 15, 15);
    color: rgb(247, 247, 242);
    height: 24px;
    width: 27px;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 3px;
  }

  .hide-btn:hover {
    background-color: rgb(122, 1, 1);
  }

  .grid-btn {
    color: rgb(128, 230, 250);
    background-color: rgb(5, 5, 5);
    width: 82px;
    height: 34px;
    margin-left: 3px;
    border-radius: 10px;
    margin-bottom: 30px;
    font-size: 7.5pt;
    letter-spacing: 0.8px;
    font-family: "Hind Siliguri", sans-serif;
  }

  .grid-btn:hover {
    background-color: rgb(5, 5, 5);
    box-shadow: 0px 2px 2px rgb(240, 247, 205);
  }

  .grid-data {
    font-size: 9.5pt;
    text-align: left;
    margin-top: 3px;
    margin-left: 2px;
    color: white;
  }

  .grid-span {
    font-size: 10.2pt;
    font-weight: bold;
    color: rgb(153, 237, 252);
  }

  .metadata-div {
    width: 230px;
  }

  .back-to-top {
    width: 52px;
    height: 52px;
    border-radius: 50%;
    margin-left: 89%;
    margin-bottom: 25px;
    font-size: 7pt;
    letter-spacing: 1.1px;
    font-family: "Hind Siliguri", sans-serif;
    color: rgb(128, 230, 250);
    background-color: rgb(5, 5, 5);
    border: none;
  }

  .back-to-top:hover {
    background-color: rgb(5, 5, 5);
    box-shadow: 2px 1px 13px rgb(240, 247, 205);
  }
}

@media screen and (min-width: 1200px) {
  body {
    background-color: rgb(43, 44, 44);
  }

  .top-grid-div {
    background-color: rgb(43, 44, 44);
  }

  strong {
    letter-spacing: 1.1px;
    font-family: "Hind Siliguri", sans-serif;
    font-size: 10.5pt;
    color: rgb(207, 207, 207);
    margin-left: 60px;
  }

  .filter {
    height: 23pt;
    width: 360px;
    margin-top: 40px;
    margin-left: 40px;
    margin-bottom: 90px;
    border-radius: 30px;
    background-color: rgb(59, 59, 56);
    font-size: 14px;
    padding-left: 25px;
    color: rgb(139, 139, 138);
    border: 1px inset rgb(97, 95, 95);
    box-shadow: none;
    letter-spacing: 1.1px;
    font-family: "Hind Siliguri", sans-serif;
  }

  .filter:hover {
    background-color: rgb(59, 59, 56);
  }

  .home {
    width: 57px;
    height: 57px;
    border-radius: 50%;
    background-color: rgb(5, 5, 5);
    margin-left: 42%;
    font-size: 6pt;
    letter-spacing: 1.1px;
    font-family: "Hind Siliguri", sans-serif;
    color: rgb(128, 230, 250);
    border: none;
  }

  .home:hover {
    background-color: rgb(5, 5, 5);
    box-shadow: 2px 1px 13px rgb(240, 247, 205);
  }

  .grid-div {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 25px;
    padding: 50px 0px 35px 50px;
  }

  .grid-img {
    max-height: 346px;
    box-shadow: 1px 18px 15px 2px rgb(7, 7, 7);
    height: auto;
    width: auto;
    margin-bottom: 5px;
  }

  .border-grid {
    border-bottom: 1px solid white;
    width: 250px;
    margin-bottom: 10px;
  }

  .grid-img:hover {
    box-shadow: 1px 1px 1px 2px rgb(240, 247, 205);
  }

  .hide-btn {
    background-color: rgb(87, 15, 15);
    color: rgb(247, 247, 242);
    height: 24px;
    width: 27px;
    border-radius: 10px;
    margin-bottom: 5px;
    margin-left: 3px;
  }

  .hide-btn:hover {
    background-color: rgb(122, 1, 1);
  }

  .grid-btn {
    color: rgb(128, 230, 250);
    background-color: rgb(5, 5, 5);
    width: 90px;
    height: 33px;
    margin-left: 3px;
    border-radius: 10px;
    margin-bottom: 25px;
    font-size: 7.5pt;
    letter-spacing: 1px;
    font-family: "Hind Siliguri", sans-serif;
  }

  .grid-btn:hover {
    background-color: rgb(5, 5, 5);
    box-shadow: 0px 2px 2px rgb(240, 247, 205);
  }

  .grid-data {
    font-size: 9pt;
    text-align: left;
    margin-top: 3px;
    color: white;
  }

  .grid-span {
    font-size: 9.75pt;
    font-weight: bold;
    color: rgb(153, 237, 252);
  }

  .metadata-div {
    width: 250px;
  }

  .back-to-top {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-left: 90%;
    margin-bottom: 45px;
    font-size: 8pt;
    letter-spacing: 1.1px;
    font-family: "Hind Siliguri", sans-serif;
    color: rgb(128, 230, 250);
    background-color: rgb(5, 5, 5);
    border: none;
  }

  .back-to-top:hover {
    background-color: rgb(5, 5, 5);
    box-shadow: 2px 1px 13px rgb(240, 247, 205);
  }
}

@media screen and (min-width: 1400px) {
  body {
    background-color: rgb(43, 44, 44);
  }

  .top-grid-div {
    background-color: rgb(43, 44, 44);
  }

  strong {
    letter-spacing: 1.1px;
    font-family: "Hind Siliguri", sans-serif;
    font-size: 11pt;
    color: rgb(207, 207, 207);
    margin-left: 65px;
  }

  .filter {
    height: 27pt;
    width: 400px;
    margin-top: 50px;
    margin-left: 35px;
    margin-bottom: 90px;
    border-radius: 30px;
    background-color: rgb(59, 59, 56);
    font-size: 12pt;
    padding-left: 25px;
    color: rgb(139, 139, 138);
    border: 1px inset rgb(97, 95, 95);
    box-shadow: none;
    letter-spacing: 1.1px;
    font-family: "Hind Siliguri", sans-serif;
  }

  .filter:hover {
    background-color: rgb(59, 59, 56);
  }

  .home {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: rgb(5, 5, 5);
    margin-left: 45%;
    font-size: 7pt;
    letter-spacing: 1.1px;
    font-family: "Hind Siliguri", sans-serif;
    color: rgb(128, 230, 250);
    border: none;
  }

  .home:hover {
    background-color: rgb(5, 5, 5);
    box-shadow: 2px 1px 13px rgb(240, 247, 205);
  }

  .grid-div {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 60px;
    padding: 50px 0px 20px 60px;
  }

  .grid-img {
    max-height: 370px;
    box-shadow: 1px 18px 15px 2px rgb(7, 7, 7);
    height: auto;
    width: auto;
    margin-bottom: 10px;
  }

  .border-grid {
    border-bottom: 1px solid white;
    width: 280px;
    margin-bottom: 10px;
  }

  .grid-btn {
    color: rgb(128, 230, 250);
    background-color: rgb(5, 5, 5);
    width: 95px;
    height: 36px;
    margin-left: 3px;
    border-radius: 10px;
    margin-bottom: 10px;
    font-size: 8.5pt;
    letter-spacing: 1px;
    font-family: "Hind Siliguri", sans-serif;
  }

  .grid-btn:hover {
    background-color: rgb(5, 5, 5);
    box-shadow: 0px 2px 2px rgb(240, 247, 205);
  }

  .hide-btn {
    background-color: rgb(87, 15, 15);
    color: rgb(247, 247, 242);
    height: 25px;
    width: 27px;
    border-radius: 10px;
    margin-bottom: 5px;
    margin-left: 3px;
  }

  .hide-btn:hover {
    background-color: rgb(122, 1, 1);
  }

  .grid-data {
    text-align: left;
    margin-top: 3px;
    color: white;
    font-weight: lighter;
    font-size: 10pt;
    line-height: 13.5pt;
  }

  .grid-span {
    font-size: 10pt;
    color: rgb(153, 237, 252);
  }

  .metadata-div {
    width: 280px;
  }
  .back-to-top {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-left: 90%;
    margin-bottom: 45px;
    font-size: 8pt;
    letter-spacing: 1.1px;
    font-family: "Hind Siliguri", sans-serif;
    color: rgb(128, 230, 250);
    background-color: rgb(5, 5, 5);
    border: none;
  }

  .back-to-top:hover {
    background-color: rgb(5, 5, 5);
    box-shadow: 2px 1px 13px rgb(240, 247, 205);
  }
}

@media screen and (min-width: 1600px) {
  body {
    background-color: rgb(43, 44, 44);
  }

  strong {
    letter-spacing: 1.1px;
    font-family: "Hind Siliguri", sans-serif;
    font-size: 12pt;
    color: rgb(207, 207, 207);
    margin-left: 90px;
  }

  .filter {
    height: 29pt;
    width: 400px;
    margin-top: 70px;
    margin-left: 15px;
    margin-bottom: 80px;
    border-radius: 30px;
    background-color: rgb(59, 59, 56);
    font-size: 12pt;
    padding-left: 25px;
    padding-right: 15px;
    color: rgb(139, 139, 138);
    border: 1px inset rgb(97, 95, 95);
    box-shadow: none;
    letter-spacing: 1.1px;
    font-family: "Hind Siliguri", sans-serif;
  }

  .home {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    margin-left: 50%;
    font-size: 8pt;
    letter-spacing: 1.1px;
    font-family: "Hind Siliguri", sans-serif;
    color: rgb(128, 230, 250);
    background-color: rgb(5, 5, 5);
    border: none;
  }

  .home:hover {
    background-color: rgb(5, 5, 5);
    box-shadow: 2px 1px 13px rgb(240, 247, 205);
  }

  .grid-div {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 45px;
    padding: 50px 0px 35px 90px;
  }

  .grid-img {
    max-height: 420px;
    box-shadow: 1px 18px 15px 2px rgb(7, 7, 7);
    height: auto;
    width: auto;
    margin-bottom: 5px;
  }

  .border-grid {
    border-bottom: 1px solid white;
    width: 300px;
    margin-bottom: 10px;
  }

  .grid-img:hover {
    box-shadow: 1px 1px 1px 2px rgb(240, 247, 205);
  }

  .hide-btn {
    background-color: rgb(87, 15, 15);
    color: rgb(247, 247, 242);
    height: 26px;
    width: 28px;
    border-radius: 10px;
    margin-bottom: 10px;
    margin-left: 3px;
  }

  .hide-btn:hover {
    background-color: rgb(122, 1, 1);
  }

  .grid-btn {
    color: rgb(128, 230, 250);
    background-color: rgb(5, 5, 5);
    width: 100px;
    height: 36px;
    margin-left: 3px;
    border-radius: 10px;
    margin-bottom: 10px;
    font-size: 8.5pt;
    letter-spacing: 1.1px;
    font-family: "Hind Siliguri", sans-serif;
  }

  .grid-btn:hover {
    background-color: rgb(5, 5, 5);
    box-shadow: 0px 2px 2px rgb(240, 247, 205);
  }

  .grid-data {
    text-align: left;
    margin-top: 3px;
    color: white;
    font-size: 11pt;
    line-height: 15pt;
  }

  .grid-span {
    font-size: 11pt;
    color: rgb(153, 237, 252);
  }

  .metadata-div {
    width: 300px;
  }

  .back-to-top {
    width: 62px;
    height: 62px;
    border-radius: 50%;
    margin-left: 90%;
    margin-bottom: 45px;
    font-size: 9pt;
    letter-spacing: 1.1px;
    font-family: "Hind Siliguri", sans-serif;
    color: rgb(128, 230, 250);
    background-color: rgb(5, 5, 5);
    border: none;
  }

  .back-to-top:hover {
    background-color: rgb(5, 5, 5);
    box-shadow: 2px 1px 13px rgb(240, 247, 205);
  }
}

@media screen and (min-width: 1900px) {
  body {
    background-color: rgb(43, 44, 44);
  }

  .top-grid-div {
    background-color: rgb(43, 44, 44);
    height: 100vh;
  }

  .filter {
    height: 30pt;
    width: 400px;
    margin-top: 60px;
    margin-left: 47px;
    margin-bottom: 100px;
    border-radius: 30px;
    background-color: rgb(59, 59, 56);
    font-size: 19px;
    padding-left: 25px;
    color: rgb(139, 139, 138);
    border: 1px inset rgb(97, 95, 95);
    box-shadow: none;
    letter-spacing: 1.1px;
    font-family: "Hind Siliguri", sans-serif;
  }

  .filter:hover {
    background-color: rgb(59, 59, 56);
  }

  .home {
    width: 69px;
    height: 69px;
    border-radius: 50%;
    background-color: rgb(5, 5, 5);
    margin-left: 54%;
    font-size: 9pt;
    letter-spacing: 1.1px;
    font-family: "Hind Siliguri", sans-serif;
    color: rgb(128, 230, 250);
    border: none;
  }

  .home:hover {
    background-color: rgb(5, 5, 5);
    box-shadow: 2px 1px 13px rgb(240, 247, 205);
  }

  .grid-div {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 65px;
    padding: 50px 0px 40px 90px;
  }

  .grid-img {
    max-height: 470px;
    box-shadow: 1px 18px 15px 2px rgb(7, 7, 7);
    height: auto;
    width: auto;
    margin-bottom: 10px;
  }

  .border-grid {
    border-bottom: 1px solid white;
    width: 332px;
    margin-bottom: 15px;
    margin-top: 20px;
  }

  .grid-img:hover {
    box-shadow: 1px 1px 1px 2px rgb(240, 247, 205);
  }

  .hide-btn {
    background-color: rgb(87, 15, 15);
    color: rgb(247, 247, 242);
    height: 28px;
    width: 31px;
    border-radius: 10px;
    margin-bottom: 7px;
    margin-left: 3px;
  }

  .hide-btn:hover {
    background-color: rgb(122, 1, 1);
  }

  .grid-btn {
    color: rgb(128, 230, 250);
    background-color: rgb(5, 5, 5);
    width: 100px;
    height: 36px;
    margin-left: 3px;
    border-radius: 10px;
    margin-bottom: 10px;
    font-size: 9pt;
    font-family: "Hind Siliguri", sans-serif;
    letter-spacing: 1px;
  }

  .grid-btn:hover {
    background-color: rgb(5, 5, 5);
    box-shadow: 0px 2px 2px rgb(240, 247, 205);
  }

  .grid-data {
    text-align: left;
    margin-top: 3px;
    color: white;
  }

  .grid-span {
    font-weight: bold;
    color: rgb(153, 237, 252);
  }

  .metadata-div {
    width: 330px;
  }

  .back-to-top {
    width: 67px;
    height: 67px;
    border-radius: 50%;
    margin-left: 90%;
    margin-bottom: 45px;
    font-size: 9pt;
    letter-spacing: 1.1px;
    font-family: "Hind Siliguri", sans-serif;
    color: rgb(128, 230, 250);
    background-color: rgb(5, 5, 5);
    border: none;
  }

  .back-to-top:hover {
    background-color: rgb(5, 5, 5);
    box-shadow: 2px 1px 13px rgb(240, 247, 205);
  }
}

@media screen and (min-width: 2500px) {
  body {
    background-color: rgb(43, 44, 44);
  }

  .top-grid-div {
    background-color: rgb(43, 44, 44);
    height: 100vh;
  }

  .filter {
    height: 40pt;
    width: 470px;
    margin-top: 60px;
    margin-left: 47px;
    margin-bottom: 100px;
    border-radius: 30px;
    background-color: rgb(59, 59, 56);
    font-size: 16px;
    padding-left: 25px;
    color: rgb(139, 139, 138);
    border: 1px inset rgb(97, 95, 95);
    box-shadow: none;
    letter-spacing: 1.1px;
    font-family: "Hind Siliguri", sans-serif;
  }

  .filter:hover {
    background-color: rgb(59, 59, 56);
  }

  .home {
    width: 89px;
    height: 89px;
    border-radius: 50%;
    background-color: rgb(5, 5, 5);
    margin-left: 60%;
    font-size: 11pt;
    letter-spacing: 1.1px;
    font-family: "Hind Siliguri", sans-serif;
    color: rgb(128, 230, 250);
    border: none;
  }

  .home:hover {
    background-color: rgb(5, 5, 5);
    box-shadow: 2px 1px 13px rgb(240, 247, 205);
  }

  .grid-div {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 65px;
    padding: 50px 0px 40px 90px;
  }

  .grid-img {
    max-height: 600px;
    box-shadow: 1px 18px 15px 2px rgb(7, 7, 7);
    height: auto;
    width: auto;
    margin-bottom: 10px;
  }

  .border-grid {
    border-bottom: 1px solid white;
    width: 392px;
    margin-bottom: 15px;
    margin-top: 20px;
  }

  .grid-img:hover {
    box-shadow: 1px 1px 1px 2px rgb(240, 247, 205);
  }

  .hide-btn {
    background-color: rgb(87, 15, 15);
    color: rgb(247, 247, 242);
    height: 38px;
    width: 41px;
    border-radius: 10px;
    margin-bottom: 7px;
    margin-left: 3px;
  }

  .hide-btn:hover {
    background-color: rgb(122, 1, 1);
  }

  .grid-btn {
    color: rgb(128, 230, 250);
    background-color: rgb(5, 5, 5);
    width: 135px;
    height: 46px;
    margin-left: 3px;
    border-radius: 10px;
    margin-bottom: 10px;
    font-size: 11pt;
    font-family: "Hind Siliguri", sans-serif;
    letter-spacing: 1px;
  }

  .grid-btn:hover {
    background-color: rgb(5, 5, 5);
    box-shadow: 0px 2px 2px rgb(240, 247, 205);
  }

  .grid-data {
    text-align: left;
    margin-top: 3px;
    color: white;
    font-size: 16pt; 
    line-height: 20pt; 
  }

  .grid-span {
    font-weight: bold;
    font-size: 16pt; 
    color: rgb(153, 237, 252);
  }

  .metadata-div {
    width: 410px;
  }

  .back-to-top {
    width: 67px;
    height: 67px;
    border-radius: 50%;
    margin-left: 90%;
    margin-bottom: 45px;
    font-size: 9pt;
    letter-spacing: 1.1px;
    font-family: "Hind Siliguri", sans-serif;
    color: rgb(128, 230, 250);
    background-color: rgb(5, 5, 5);
    border: none;
  }

  .back-to-top:hover {
    background-color: rgb(5, 5, 5);
    box-shadow: 2px 1px 13px rgb(240, 247, 205);
  }

}
