.slide-div {
  background-color: rgb(5, 5, 5);
  padding: 50px;
  height: auto;
}

body {
  background-color: rgb(5, 5, 5);
}

.view-pdf {
  color: blanchedalmond;
  padding-bottom: 0px;
  font-size: 10pt;
  letter-spacing: 1.1px;
  font-family: "Hind Siliguri", sans-serif;
  margin-left: 3px;
}

.button-54:hover {
  background-color: whitesmoke;
}

.each-fade {
  padding-top: 50px;
  background-color: rgb(5, 5, 5);
}

.vh-image {
  max-height: 700px;
  height: auto;
  width: auto;
  margin-left: 6.498in;
  margin-bottom: 50px;
  box-shadow: 4px 4px 10px 4px rgb(22, 22, 22);
}

.war {
  max-height: 700px;
  height: auto;
  width: auto;
  margin-left: 6.2in;
  margin-bottom: 50px;
  box-shadow: 4px 4px 10px 4px rgb(22, 22, 22);
}

.title {
  color: white;
  text-align: center;
}
@media screen and (min-width: 400px) {
  body {
    background-color: rgb(5, 5, 5);
  }

  .slide-div {
    background-color: rgb(5, 5, 5);
    padding: 40px;
    height: 160vh;
  }

  .each-fade {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding-top: 50px;
    border: 4px inset white;
    padding: 30px 40px 40px 40px;
  }

  .vh-image {
    max-height: 270px;
    height: auto;
    width: auto;
    margin-top: 15%;
    margin-left: 0%;
    margin-bottom: 10px;
    box-shadow: 4px 4px 10px 4px rgb(22, 22, 22);
  }

  .metadata {
    width: 300px;
    text-align: justify;
    line-height: 10pt;
    margin-top: 1px;
    opacity: 1;
    margin-left: 1%;
  }

  span {
    font-weight: bold;
    color: rgb(128, 230, 250);
  }

  .title {
    color: white;
    text-align: left;
    font-size: 9pt;
    width: 200px;
    margin-left: 5%;
    line-height: 12pt;
    margin-top: 10.9%;
    margin-bottom: 3%;
    font-family: "Allerta Stencil", sans-serif;
  }

  .small-data {
    color: white;
    font-size: 7pt;
    line-height: 8.5pt;
    letter-spacing: 0.5px;
    width: 170px;
    margin-top: 0.5%;
    margin-left: 5%;
  }

  .see-zine-btn {
    color: black;
    background-color: rgb(246, 250, 226);
    margin-left: 0%;
    border-right: 5px solid rgb(80, 219, 253);
    border-left: 5px solid rgb(80, 219, 253);
    width: 120px;
    height: 34px;
    border-radius: 5%;
    margin-bottom: 0px;
    font-size: 9pt;
    letter-spacing: 1.1px;
    font-family: "Hind Siliguri", sans-serif;
  }

  .see-zine-btn:hover {
    background-color: rgb(5, 5, 5);
    color: rgb(80, 219, 253);
    box-shadow: 0px 2px 2px rgb(240, 247, 205);
  }
}

@media screen and (min-width: 700px) {
  .slide-div {
    background-color: rgb(5, 5, 5);
    padding: 20px;
    height: 170vh;
  }

  .each-fade {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding-top: 50px;
    border: 4px inset white;
    padding: 30px 30px 30px 30px;
  }

  .vh-image {
    max-height: 380px;
    height: auto;
    width: auto;
    margin-top: 14%;
    margin-left: 13%;
    margin-bottom: 10px;
    box-shadow: 4px 4px 10px 4px rgb(22, 22, 22);
  }

  .metadata {
    width: 480px;
    text-align: justify;
    line-height: 15pt;
    margin-top: 1px;
    opacity: 1;
    margin-left: 9%;
  }

  span {
    font-weight: bold;
    color: rgb(128, 230, 250);
  }

  .title {
    color: white;
    text-align: left;
    font-size: 11pt;
    width: 280px;
    margin-left: 7%;
    line-height: 16pt;
    margin-top: 11.3%;
    margin-bottom: 3%;
    font-family: "Allerta Stencil", sans-serif;
  }

  .small-data {
    color: white;
    font-size: 8pt;
    line-height: 10.5pt;
    letter-spacing: 0.5px;
    width: 260px;
    margin-top: 0.5%;
    margin-left: 7%;
  }
}

@media screen and (min-width: 700px) and (min-height: 1000px) {
  .slide-div {
    background-color: rgb(5, 5, 5);
    padding: 40px;
    height: 60vh;
  }
}

@media screen and (min-width: 1000px) {
  .slide-div {
    background-color: rgb(5, 5, 5);
    padding: 20px 50px 50px 50px;
    margin: auto;
    height: 100%;
  }

  .each-fade {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    border: 4px inset white;
    padding: 20px 20px 20px 60px;
  }

  .vh-image {
    max-height: 420px;
    height: auto;
    width: auto;
    margin-top: 4%;
    margin-left: 27%;
    margin-bottom: 10px;
    box-shadow: 4px 4px 10px 4px rgb(22, 22, 22);
  }

  .metadata {
    width: 460px;
    text-align: justify;
    line-height: 16pt;
    margin-top: 1px;
    opacity: 1;
    margin-left: 14%;
  }

  span {
    font-weight: bold;
    color: rgb(128, 230, 250);
  }

  .title {
    color: white;
    text-align: left;
    font-size: 13pt;
    width: 380px;
    margin-left: 13%;
    line-height: 20pt;
    margin-top: 4.9%;
    margin-bottom: 3%;
    font-family: "Allerta Stencil", sans-serif;
  }

  .small-data {
    color: white;
    font-size: 9pt;
    line-height: 14pt;
    letter-spacing: 0.5px;
    width: 380px;
    margin-top: 0.5%;
    margin-left: 13%;
  }
}

@media screen and (min-width: 1000px) and (min-height: 1000px) {
  .slide-div {
    background-color: rgb(5, 5, 5);
    padding: 40px;
    margin: auto;
    height: 55vh;
  }

  .each-fade {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding-top: 50px;
    border: 4px inset white;
    padding: 50px 50px 50px 50px;
  }

  .vh-image {
    max-height: 420px;
    height: auto;
    width: auto;
    margin-top: 4%;
    margin-left: 27%;
    margin-bottom: 10px;
    box-shadow: 4px 4px 10px 4px rgb(22, 22, 22);
  }

  .metadata {
    width: 480px;
    text-align: justify;
    line-height: 16pt;
    margin-top: 1px;
    opacity: 1;
    margin-left: 14%;
  }

  span {
    font-weight: bold;
    color: rgb(128, 230, 250);
  }

  .title {
    color: white;
    text-align: left;
    font-size: 14pt;
    width: 400px;
    margin-left: 13%;
    line-height: 20pt;
    margin-top: 2.9%;
    margin-bottom: 3%;
    font-family: "Allerta Stencil", sans-serif;
  }

  .small-data {
    color: white;
    font-size: 10pt;
    line-height: 14.5pt;
    letter-spacing: 0.5px;
    width: 400px;
    margin-top: 0.5%;
    margin-left: 13%;
  }

  .button-54 {
    font-family: "Open Sans", sans-serif;
    font-size: 11px;
    letter-spacing: 2px;
    text-decoration: none;
    text-transform: uppercase;
    color: rgb(50, 50, 51);
    cursor: pointer;
    border: 3px solid;
    padding: 0.75em 15em;
    box-shadow: 1px 1px 0px 0px, 2px 2px 0px 0px, 3px 3px 0px 0px,
      4px 4px 0px 0px, 5px 5px 0px 0px;
    position: relative;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    margin-bottom: 15px;
    margin-top: 10px;
  }

  .button-54:active {
    box-shadow: 0px 0px 0px 0px;
    top: 5px;
    left: 5px;
  }

  @media (min-width: 508px) {
    .button-54 {
      padding: 0.25em 0.75em;
    }
  }
}

@media screen and (min-width: 1100px) {
  .slide-div {
    background-color: rgb(5, 5, 5);
    padding: 20px 50px 50px 50px;
    height: 100vh;
  }

  .each-fade {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding-top: 50px;
    border: 4px inset white;
    padding: 50px 50px 50px 50px;
  }

  .vh-image {
    max-height: 460px;
    height: auto;
    width: auto;
    margin-top: 4%;
    margin-left: 26%;
    margin-bottom: 10px;
    box-shadow: 4px 4px 10px 4px rgb(22, 22, 22);
    border: 2px solid black;
  }

  .vh-image:hover {
    box-shadow: 0px 0px 6px rgb(240, 247, 205);
    border: 2px solid black;
  }

  .metadata {
    width: 480px;
    text-align: justify;
    line-height: 16pt;
    margin-top: 3%;
    opacity: 1;
    margin-left: 18%;
  }

  span {
    font-weight: bold;
    color: rgb(128, 230, 250);
  }

  .title {
    color: white;
    text-align: left;
    font-size: 16pt;
    width: 500px;
    margin-left: 13%;
    line-height: 20pt;
    margin-top: 3.2%;
    margin-bottom: 6%;
    font-family: "Allerta Stencil", sans-serif;
  }

  .small-data {
    color: white;
    font-size: 9.5pt;
    line-height: 15pt;
    letter-spacing: 0.5px;
    width: 400px;
    margin-top: 0.5%;
    margin-left: 13%;
  }

  .see-zine-btn {
    color: black;
    background-color: rgb(246, 250, 226);
    margin-left: 0%;
    border-right: 5px solid rgb(80, 219, 253);
    border-left: 5px solid rgb(80, 219, 253);
    width: 190px;
    height: 43px;
    border-radius: 5%;
    margin-bottom: 0px;
    font-size: 12pt;
    letter-spacing: 1.1px;
    font-family: "Hind Siliguri", sans-serif;
  }

  .see-zine-btn:hover {
    background-color: rgb(5, 5, 5);
    color: rgb(80, 219, 253);
    box-shadow: 0px 2px 2px rgb(240, 247, 205);
  }
  .button-54 {
    font-family: "Open Sans", sans-serif;
    font-size: 9.5pt;
    letter-spacing: 2px;
    text-decoration: none;
    text-transform: uppercase;
    color: rgb(50, 50, 51);
    cursor: pointer;
    border: 3px solid;
    padding: 0.75em 15em;
    box-shadow: 1px 1px 0px 0px, 2px 2px 0px 0px, 3px 3px 0px 0px,
      4px 4px 0px 0px, 5px 5px 0px 0px;
    position: relative;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    margin-bottom: 15px;
    margin-top: 10px;
  }

  .button-54:active {
    box-shadow: 0px 0px 0px 0px;
    top: 5px;
    left: 5px;
  }

  @media (min-width: 508px) {
    .button-54 {
      padding: 0.25em 0.75em;
    }
  }
}

@media screen and (min-width: 1200px) {
  body {
    background-color: rgb(5, 5, 5);
  }

  .slide-div {
    background-color: rgb(5, 5, 5);
    padding: 10px 20px 0px 20px;
    height: 110vh;
  }

  .each-fade {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding-top: 50px;
    border: 4px inset white;
    padding: 50px 50px 50px 50px;
  }

  .vh-image {
    max-height: 510px;
    height: auto;
    width: auto;
    margin-top: 2%;
    margin-left: 45%;
    margin-bottom: 0px;
    box-shadow: 4px 4px 10px 4px rgb(22, 22, 22);
    border: 2px solid black;
  }

  .vh-image:hover {
    box-shadow: 0px 0px 6px rgb(240, 247, 205);
    border: 2px solid black;
  }

  .metadata {
    width: 460px;
    text-align: justify;
    line-height: 18pt;
    margin-top: 1px;
    opacity: 1;
    margin-left: 31%;
  }

  span {
    font-weight: bold;
    color: rgb(128, 230, 250);
  }

  .title {
    color: white;
    text-align: left;
    font-size: 15.5pt;
    width: 540px;
    margin-left: 13%;
    line-height: 20pt;
    margin-top: 7%;
    margin-bottom: 5%;
    font-family: "Allerta Stencil", sans-serif;
  }

  .small-data {
    color: white;
    font-size: 10.5pt;
    letter-spacing: 0.5px;
    line-height: 17pt;
    width: 460px;
    margin-top: 0.5%;
    margin-left: 13%;
  }

  .see-zine-btn {
    color: black;
    background-color: rgb(248, 250, 226);
    border-right: 5px solid rgb(9, 10, 10);
    border-left: 5px solid rgb(9, 10, 10);
    width: 180px;
    height: 38px;
    margin-bottom: 0px;
    margin-top: 27px;
    font-size: 11pt;
    border-radius: 20px;
    letter-spacing: 1.1px;
    margin-bottom: 15px;
    cursor: pointer;
  }

  .see-zine-btn:hover {
    color: black;
    background-color: rgb(248, 250, 226);
    border-right: 4px solid rgb(9, 10, 10);
    border-left: 4px solid rgb(9, 10, 10);
  }
}

@media screen and (min-width: 1300px) {
  .slide-div {
    background-color: rgb(5, 5, 5);
    padding: 10px 20px 0px 20px;
    height: 100vh;
  }
}

@media screen and (min-width: 1400px) {
  body {
    background-color: rgb(5, 5, 5);
  }

  .slide-div {
    background-color: rgb(5, 5, 5);
    padding: 20px 50px 50px 50px;
    height: 85vh;
  }

  .each-fade {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding-top: 50px;
    border: 4px inset white;
    padding: 50px 50px 50px 50px;
  }

  .vh-image {
    max-height: 490px;
    height: auto;
    width: auto;
    margin-top: 4%;
    margin-left: 49%;
    margin-bottom: 30px;
    box-shadow: 4px 4px 10px 4px rgb(22, 22, 22);
    border: 2px solid black;
  }

  .vh-image:hover {
    box-shadow: 0px 0px 6px rgb(240, 247, 205);
    border: 2px solid black;
  }

  .metadata {
    display: block;
    width: 520px;
    text-align: justify;
    align-content: center;
    align-items: center;
    line-height: 18pt;
    opacity: 1;
    margin-left: 32%;
  }

  span {
    font-weight: bold;
    color: rgb(128, 230, 250);
  }

  .title {
    color: white;
    text-align: left;
    font-size: 14.6pt;
    width: 590px;
    margin-left: 13%;
    line-height: 20pt;
    margin-top: 8%;
    margin-bottom: 5%;
    font-family: "Allerta Stencil", sans-serif;
  }

  .small-data {
    color: white;
    font-size: 11pt;
    letter-spacing: 0.5px;
    width: 530px;
    margin-top: 0.5%;
    margin-left: 13%;
  }

  .button-54 {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    letter-spacing: 2px;
    text-decoration: none;
    text-transform: uppercase;
    color: rgb(50, 50, 51);
    cursor: pointer;
    border: 3px solid;
    padding: 0.75em 15em;
    box-shadow: 1px 1px 0px 0px, 2px 2px 0px 0px, 3px 3px 0px 0px,
      4px 4px 0px 0px, 5px 5px 0px 0px;
    position: relative;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    margin-bottom: 15px;
  }

  .button-54:active {
    box-shadow: 0px 0px 0px 0px;
    top: 5px;
    left: 5px;
  }

  @media (min-width: 508px) {
    .button-54 {
      padding: 0.25em 0.75em;
    }
  }
}

@media screen and (min-width: 1600px) {
  body {
    background-color: rgb(5, 5, 5);
    box-sizing: border-box;
  }

  .slide-div {
    background-color: rgb(5, 5, 5);
    padding: 50px;
    height: 100%;
  }

  .each-fade {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding-top: 50px;
    border: 4px inset white;
    padding: 50px 50px 35px 50px;
  }

  .vh-image {
    max-height: 640px;
    height: auto;
    width: auto;
    margin-top: 4%;
    margin-left: 40%;
    margin-bottom: 50px;
    box-shadow: 4px 4px 10px 4px rgb(22, 22, 22);
    border: 2px solid black;
  }

  .vh-image:hover {
    box-shadow: 0px 0px 8px rgb(240, 247, 205);
    border: 2px solid black;
  }

  .metadata {
    width: 680px;
    text-align: justify;
    line-height: 20pt;
    opacity: 1;
    margin-left: 35%;
  }

  span {
    font-weight: bold;
    color: rgb(88, 216, 248);
  }

  .title {
    color: white;
    text-align: left;
    font-size: 18pt;
    width: 600px;
    margin-left: 11%;
    line-height: 22pt;
    margin-top: 7.5%;
    margin-bottom: 5%;
    font-family: "Allerta Stencil", sans-serif;
  }

  .small-data {
    color: white;
    font-size: 13pt;
    letter-spacing: 0.5px;
    width: 590px;
    margin-top: 1.3%;
    margin-left: 11%;
    line-height: 19pt;
    font-family: "Hind Siliguri", sans-serif;
  }
  .see-zine-btn {
    color: black;
    background-color: rgb(248, 250, 226);
    border-right: 5px solid rgb(9, 10, 10);
    border-left: 5px solid rgb(9, 10, 10);
    width: 255px;
    height: 45px;
    margin-bottom: 0px;
    font-size: 13.5pt;
    border-radius: 20px;
    letter-spacing: 1.1px;
    margin-bottom: 15px;
    cursor: pointer;
  }

  .see-zine-btn:hover {
    color: black;
    background-color: rgb(248, 250, 226);
    border-right: 4px solid rgb(9, 10, 10);
    border-left: 4px solid rgb(9, 10, 10);
  }

  .button-54 {
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    letter-spacing: 2px;
    text-decoration: none;
    text-transform: uppercase;
    color: rgb(50, 50, 51);
    cursor: pointer;
    border: 3px solid;
    padding: 0.75em 15em;
    box-shadow: 1px 1px 0px 0px, 2px 2px 0px 0px, 3px 3px 0px 0px,
      4px 4px 0px 0px, 5px 5px 0px 0px;
    position: relative;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    margin-bottom: 15px;
    background-color: rgb(253, 252, 251);
  }

  .button-54:active {
    box-shadow: 0px 0px 0px 0px;
    top: 5px;
    left: 5px;
  }

  @media (min-width: 768px) {
    .button-54 {
      padding: 0.25em 0.75em;
    }
  }
}

@media screen and (min-width: 1900px) {
  .slide-div {
    background-color: rgb(5, 5, 5);
    padding: 20px 50px 50px 50px;
    height: 85vh;
  }

  .each-fade {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding-top: 50px;
    border: 4px inset white;
    padding: 50px 50px 50px 50px;
  }

  .vh-image {
    max-height: 660px;
    height: auto;
    width: auto;
    margin-top: 4%;
    margin-left: 46%;
    margin-bottom: 50px;
    box-shadow: 4px 4px 10px 4px rgb(22, 22, 22);
    border: 2px solid black;
  }

  .vh-image:hover {
    box-shadow: 0px 0px 9px rgb(240, 247, 205);
    border: 2px solid black;
  }

  .metadata {
    width: 680px;
    text-align: justify;
    line-height: 19pt;
    opacity: 1;
    margin-left: 39%;
  }

  span {
    font-weight: bold;
    color: rgb(128, 230, 250);
  }

  .title {
    color: white;
    background-color: none;
    text-align: left;
    font-size: 20pt;
    width: 680px;
    margin-left: 13%;
    line-height: 28pt;
    margin-top: 7.3%;
    margin-bottom: 5%;
    font-family: "Allerta Stencil", sans-serif;
  }

  .small-data {
    color: white;
    font-size: 14.3pt;
    letter-spacing: 0.5px;
    width: 630px;
    margin-top: 1.3%;
    margin-left: 13%;
    font-family: "Allerta Stencil", sans-serif;
  }

  .button-54 {
    font-family: "Open Sans", sans-serif;
    font-size: 18px;
    letter-spacing: 2px;
    text-decoration: none;
    text-transform: uppercase;
    color: rgb(50, 50, 51);
    cursor: pointer;
    border: 3px solid;
    padding: 0.75em 15em;
    box-shadow: 1px 1px 0px 0px, 2px 2px 0px 0px, 3px 3px 0px 0px,
      4px 4px 0px 0px, 5px 5px 0px 0px;
    position: relative;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    margin-bottom: 15px;
    background-color: rgb(253, 252, 251);
  }

  .button-54:active {
    box-shadow: 0px 0px 0px 0px;
    top: 5px;
    left: 5px;
  }

  @media (min-width: 768px) {
    .button-54 {
      padding: 0.25em 0.75em;
    }
  }
}
